html { scroll-behavior: smooth; }

.ant-image-preview-img {
  max-width: 80%;
  max-height: 90%;
}

.locl-show-more > span > span {
  display: inline !important;
  max-width: none !important;
}

body:has(.locl-modal_open) {
  overflow: hidden;
}
.intercom-lightweight-app {
  z-index: 999 !important;
}

#onetrust-consent-sdk #ot-sdk-btn-floating.ot-floating-button { display: none; }

.country-name { color: #000000 }

:target { scroll-margin-top: 80px; }

.locl-legal-page__description_link {
  font-weight: 600;
  color: var(--secondary-color-500);
  text-decoration: underline;

  &:hover {
    color: var(--primary-color-500);
  }
}
