.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid grey;
  box-shadow: 0px 0px 2px 1px var(--dark-color-200);
}

.ant-input-affix-wrapper-focused {
  border: 1px solid grey;
  box-shadow: 0px 0px 2px 1px var(--dark-color-200);
}

.ant-notification {
  z-index: 999999999999;
}

.ant-avatar-group {
  // .locl-avatar__main-info { display: none; }
  .locl-avatar:not(:first-child) {
    margin-left: -8px;
  }
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view {
    &.ant-picker-cell-in-range,
    &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single),
    &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single) {
      &::before {
        background: #e6f4ff;
      }
    }
  }
}

.ant-message .ant-message-notice-wrapper {
  .ant-message-success>.anticon { color: #52c41a !important; }
  .ant-message-error>.anticon { color: #ff4d4f !important; }
  .ant-message-loading>.anticon { color: #1677ff !important; }
}
