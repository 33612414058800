.locl-down-for-maintenance {
  max-width: 1200px;
  height: max-content;
  flex: 0;

  padding: 124px;
  margin: 64px auto 64px auto;

  background-color: var(--white-color-500);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;

  @media screen and (max-width: 980px) {
    padding: 48px;
  }

  @media screen and (max-width: 520px) {
    padding: 24px;

    .locl-down-for-maintenance__icon svg {
      width: 100%;
      height: 100%;
    }

    .locl-down-for-maintenance__page-content { gap: 16px }
    .locl-down-for-maintenance__page-content__title { font-size: 24px; line-height: 20px; }
    .locl-down-for-maintenance__page-content__description { font-size: 14px; line-height: 16px; }

  }

  &__page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    &__title {
      font-size: 48px;
      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 400;
      line-height: 56px;
      color: var(--secondary-color-500);
    }

    &__description {
      font-size: 18px;
      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--dark-color-200);
      text-align: center;

      margin: 0;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
  }
}
